import React, { useState } from 'react';
import './styles.scss';

const WunderBot = ({section}) => {
  const [showMascot, setShowMascot] = useState(true);
  const [messageValue, setMessageValue] = useState('');

  const toggleMascot = () => {
    setShowMascot(!showMascot);
  };

  const sendMessage = () => {
    setShowMascot(false); // Hide mascot when sending message
    // Post message to iframe
    const iframeWindow = document.getElementById("iFrame").contentWindow;
    iframeWindow.postMessage(
      {
        type: "send-message",
        message: messageValue,
      },
      "*"
    );
  };

  const handleKeyDown = (event) => {
    if(event.key === 'Enter') {
      sendMessage();
    }
  }

  return (
    <div style={{backgroundColor: section?.wrapperbackgroundcolor || `#191919`, padding: 32}} className="wunderbot-wrapper">
      <div className='wunderbot-container'>
        <h2 className='wunderbot-header'>Ask our AI mascot anything, get an answer</h2>
        <div className="prompt-field">
          <img src="/images/how-it-works/avatar-mascot-wiseox.svg" className="" alt="Mascot Avatar" />
          <input
            type="text"
            placeholder="Ask WunderBot!"
            value={messageValue}
            onChange={(e) => setMessageValue(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <div className="submit-btn" onClick={sendMessage}>
            <img src="/images/how-it-works/chevron-right.svg" alt="Send Message" />
          </div>
        </div>

        <div className="wiseox-embed-mascot">
          {showMascot ? (
            <div className="embed-img" onClick={toggleMascot}>
              <img src="/images/how-it-works/avatar-mascot-wiseox.svg" className="" alt="Mascot Avatar" />
              <span className="embed-img-label">Ask WunderBot</span>
            </div>
          ) : null}

          <div className={`embed-iframe ${showMascot ? 'hidden' : ''}`}>
            <div className="btn-hide" onClick={toggleMascot}>Hide</div>
            <iframe id="iFrame" src="https://wiseox.ai/mascot/658dc68e0a96ca0027417f02" title="WiseOx Chat"></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WunderBot;
